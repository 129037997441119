import React, { useEffect, useState } from 'react';
import './../css/Contact.css';
import Navbar from './../navbars/NavbarNewProject';
import Footer from './../Footer';
import { storage, db } from '../firebase';
import { useAuth } from '../firebase/AuthContext';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useForm } from 'react-hook-form';



function Create() {


    const [message, setMessage] = useState('');

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
  
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    const [build, setBuild] = useState('');

  
    const handleSubmite = async (e) => {
        e.preventDefault();
        setMessage('');
    
        try {
          await addDoc(collection(db, 'entreprises'), { name: name, description: description, color: color, build: build, createdDate: serverTimestamp()  });
          setMessage('Merci ! Nous vous répondrons sous peu.');
          // Clear the input field after successful submission
          setName('');
          setDescription('');
          setColor('');
          setBuild('');
        } catch (error) {
          setMessage(`Error: ${error.message}`);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
    
        try {
          // Upload file to storage
          const storageRef = storage.ref(`files/${build.name}`);
          await storageRef.put(build);
          const fileURL    = await storageRef.getDownloadURL();
    
          // Save data to Firestore
          await db.collection('entreprises').add({
            name: name, 
            description: description, 
            color: color, 
            build: build, 
            createdAt: db.firestore.FieldValue.serverTimestamp()
          });
    
          setLoading(false);
          alert('Form submitted successfully!');
        } catch (error) {
          console.error("Error submitting form: ", error);
          setLoading(false);
        }
    };


    return (
      <div className="App" style={{ background: '#02a049' }}>
        <Navbar />
  

        <div className='ps-5 pe-5 pb-5 offre'>
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-sm-6 box-features pt-3 ps-3 pe-3 pb-2 me-4" style={{border: '1px solid #818181'}}>
                      <form>
                        <div className='col-md-12 div-details-welcome  ps-2 pe-2  pb-3'>
                            
                            <h5 className='text-success fw-bolder mt-3'> 1. Projet</h5>
                            <br /> 
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Nom du projet</span> 
                            </span>
                            <div className='input-box'>
                                <h5 className='desc-input'> Le nom du projet en 50 caractères maximum.</h5>
                                <div className='mt-3'>
                                    <input type="text" 
                                      value={name}
                                      onChange={(e) => setName(e.target.value)}
                                      className='form-control mt-2 input' placeholder='Nom du projet'
                                    />
                                </div>
                            </div>
                            <br /> 
  
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Description du projet</span> 
                            </span>
                            <h5  className='desc-input'> Présentez votre projet en 200 caractères maximum.</h5>
                            <div className='mt-3 '>
                                <textarea type="text" 
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    className='form-control mt-2 input' placeholder=''
                                  
                                />
                            </div>
                            <br /> 
  
                            
  
                            {/* 2 customization */}
                            <h5 className='text-success fw-bolder mt-3'> 2. Personnalisation</h5>
                            <br /> 
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Thème du projet</span> 
                            </span>
                            <h5  className='desc-input '> Charte graphique ou couleur dominante du projet en hex. <span className='fs-6 fw-bold'>Ex: #854858</span></h5>
                            <div className='mt-3'>
                                <input type="text" className='form-control mt-2 input' placeholder=''   />
                            </div>
                            <br /> 
  
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Logo du projet</span> 
                            </span>
                            <h5  className='desc-input'> Le logo de votre projet avec la taille <span className='fs-6'>512px * 512px</span> </h5>
                            <div className='mt-3'>
                                <input type="file" className='form-control mt-2 input'  />
                            </div>
                            <br />
  
  

                            {/* 3 .APK & .IPA */}
                            <h5 className='text-success fw-bolder mt-4'> 3. Fichier</h5>
                            <br /> 
                            <span className='title1'>
                                <span className='brand fw-bold text-decoration-none text-success'> Projet React js buildé</span> 
                            </span>
                            <h5 className='desc-input'>
                              Vous avez terminé le développement de votre projet React JS. <br /> 
                              Soumettez nous votre projet buildé: <b>"npm run build"</b>. <br /> 
                              <span className='text-dark mt-1'>Build.zip (50 Mo maximum)</span>
                            </h5>
                            <div className='mt-3'>
                                <input type="file" className='form-control mt-2 input'  />
                            </div>
                            <br />
  
                            <div className='mt-3'>
                                <button type="file" className='btn-start mt-2  fw-bold' > Créer <i class="fa-solid fa-plus"></i> </button>
                            </div>
  
                          </div>  
                      </form>
  
                  </div>
 
              </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default Create;
  
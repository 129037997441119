// src/Logout.js
import React from 'react';
import { auth } from './firebase';
import { useNavigate } from 'react-router-dom';


const Logout = () => {

    const navigate = useNavigate();
    
    const handleLogout = () => {
        auth.signOut();
        navigate('/');
    };

    return (
        <a  className="cart-icon" onClick={handleLogout}><i class="fa-solid fa-power-off mt-2"></i></a>
    );
};

export default Logout;

import React, { useEffect, useState } from 'react';
import './css/Contact.css';
import Navbar from './navbars/NavbarContact';
import Footer from './Footer';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useAuth } from '../firebase/AuthContext';
import {
    collection,
    addDoc,
    serverTimestamp,
    getDocs,
    updateDoc,
    deleteDoc,
    doc
  } from 'firebase/firestore';
import { useForm } from 'react-hook-form';



function Contact() {

  const [message, setMessage] = useState('');

  const [user, setUsers] = useState(null);
  const [error, setError] = useState('');

  const [name, setName] = useState('');
  const [country, setCountry] = useState('');
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
  
      try {
        await addDoc(collection(db, 'contacts'), { email: email, country: country, name: name, text: text, contactDate: serverTimestamp()  });
        setMessage('Merci ! Nous vous répondrons sous peu.');
        // Clear the input field after successful submission
        setCountry('');
        setText('');
        setEmail('');
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <h5 className='Brand-FleetBo mt-2'> Fleetbo <sup className='beta fw-bold'>Beta</sup> </h5>
        <div className='col-md-6 ps-2 pe-2'>
            <p className='box-subtitle'>
              <span className='title1'>
                  <span className='brand'>Empowering web developers</span> 
              </span>
              <br />
            </p> 
        </div>

        <div className='container box-form-contact  text-center justify-content-center'>
            {user ? (
              <div>
                <p>Welcome, {user.email}</p>
                <button >Logout</button>
              </div>
            ) : (
            <form onSubmit={handleSubmit}>
              <div className='justify-content-center'>
                    
                   
                    <div className='mb-1'>
                        <label className='ps-0 label'>E-mail</label>
                        <input type="email" 
                               className='form-control input'
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               required
                        />
                    </div>

                    <div className='mb-2'>
                        <label className='ps-0 label'>Pays</label>
                        <select id="country" className='form-control input' value={country} onChange={handleCountryChange}>
                            <option value="">Selectionner</option>
                            <option value="Allemagne">Allemagne</option>
                            <option value="Belgique">Belgique</option>
                            <option value="Cameroun">Cameroun</option>
                            <option value="Canada">Canada</option>
                            <option value="Côte d'ivoire">Côte d'ivoire</option>
                            <option value="France">France</option>
                            <option value="Suisse">Suisse</option>
                        </select>
                    </div>
					
					 <div className='mb-1'>
                        <label className='ps-0 label'>Nom de l'application</label>
                        <input type="email" 
                               className='form-control input'
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               required
                        />
                    </div>

                    <div className='mb-3'>
                        <label className='ps-0 label'>Présentation (200 caractères)</label>
                        <textarea type="text" 
                                  className='form-control input' 
                                  id="exampleFormControlTextarea1"
                                  rows="3"
								  maxlength="200"
                                  value={text}
                                  onChange={(e) => setText(e.target.value)}
                                  required
                        >
                        </textarea>
                    </div>
                    <div className='col-md-12 mb-3 justify-content-center d-flex'>
                      {/* <a href='/login' className='inscription me-2' >  Connexion </a> */}
                      <button type='submit' className='connexion' >  Soumettre </button>
                    </div>
                    {error && <p>{error}</p>}
                    {message && 
                     <p className='form-info text-light text-center'><i class="fa-solid fa-envelope-circle-check fs-6"></i> {message} <i class="fa-regular fa-face-smile"></i></p>
                    }     
              </div>
            </form>
            )}
        </div>

      </header>

      <Footer />
      
    </div>
  );
}

export default Contact;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import './css/Navbar.css';
import './css/Home.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';
import {  auth } from "../firebase";
import {  useNavigate } from 'react-router-dom';
import Logout from './../Logout';

const NavbarHome = () => {

  return (

  <nav className="navbar fixed-top">
    <div className='container'>
        <div className="navbar-left">
          <Link to="/home">
            <img src={logo1} className="" alt="logo" /> 
          </Link>
        </div>
        <div className="navbar-right">
          <Link to="/project" className="go-to-console fw-bolder text-success" aria-label="User Account">
             <i class="fas fa-circle-plus fs-4 me-3"></i>
          </Link>
          {/*
          <a href="/publisher" className="cart-icon">
            <i class="fa-solid fa-mobile me-2"></i>
          </a>
          */}
          <Link to="/documentation" className="cart-icon">
            <img src={assistant} className="assistant me-3" alt="logo" /> 
          </Link>
          <Logout />
        </div>
    </div>
  </nav>
  );
  
};

export default NavbarHome;
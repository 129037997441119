import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import developers from './img/developers.jpg';
import teaser from './video/teaser.mp4';
import './css/Welcome.css';
import Navbar from './navbars/Navbar';
import Footer from './Footer';




function Welcome() {

    return (
        <div className="App">
        
        <Navbar />
        <header className="App-header">
            <div className='container bg-title ms-2 me-2 row justify-content-center'>
                <div class="col-sm-6 ps-2 pe-2">
                    <br /> 
                    {/* <h5 className='Brand-FleetBo mt-5 ms-2'> FleetBo </h5> */}
                    <h5 className='Brand-FleetBo fs-1 mt-2 ms-2'> Fleetbo<sup className='text-light fw-bold fs-4'>.io</sup> </h5>
                    <div className='col-md-12 ps-2 pe-2 pt-2 pb-2 '>
                        <h1 className='float-left fs-1'>Créez des applications <br /> mobiles natives avec <br />JavaScript</h1>   
                        <p className=''>
                        <span className='title1'>
                            <span>Le premier App-as-a-service pour developeurs web. </span>
                        </span>
                        <br />
                        <Link to='/home' className='console mt-3'>Console Fleetbo <i class="fas fa-tachometer-alt me-2"></i></Link >
                        </p>
                    </div>
                </div>
                <div class="col-sm-5 bg-media">
                    <video
                         poster="https://mir-s3-cdn-cf.behance.net/project_modules/disp/583b6136197347.571361641da25.gif"
                         //onLoad={handleImageLoaded} // Appelé une fois l'image complètement chargée
                         //style={{ display: loading ? 'none' : 'block' }} // Masquer l'image jusqu'à son chargement
                         src={teaser} 
                         muted autoplay='true' playsinline='true' 
                         loop='true' id="vid">  
                    </video>
                </div>
            </div>
            {/*
            <div className='ps-3 pe-3'>
            <p className='text-desc'>Transformez vos sites web en Appli non téléchargeable.</p>
            </div>
            */}
        </header>

        <div className='formules-box'>
            <div class="container pb-4 bg-white ">
                <div class="row why-fleetbo justify-content-center">
                    <h3 className='formule mt-4 mb-4'>Aperçu de <b className='text-success'>Fleetbo </b> </h3>
                    <div class="col-sm-5 box-features ps-4 pe-4 ms-2 me-2 mt-2">
                        <h5 className='detail-fleetbo text-dark fw-bolder mt-5'>
                            Passez immédiatement au développement de vos features principales. 
                        </h5>
                        <h5 className='details-desc text-success fs-5 fw-bolder mt-3'>
                            Juste un éditeur de code et un navigateur.
                        </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc text-success fs-5'>Fleetbo s'occupe du reste ! </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-1 pb-3'>
                            <h5 className='detail-fleetbo-desc'>
                                <b className='fw-bold'>Fleetbo</b> est une technologie tout-en-un (Backend / frontend) qui permet de créer
                                rapidement des applications mobiles natives à coûts réduits avec JavaScript.
                            </h5>
                            <h5 className='detail-fleetbo-desc'>
                                Système de gestion des utilisateurs, système de
                                notifications push et accès natif aux ressources de l'appareil sont intégrés.
                            </h5>
                            <h5 className='detail-fleetbo-desc'>
                                <b className='fw-bold'> Fleetbo</b> génère des fichiers utilisables sur les plateformes Apple Store et Google Play Store.
                            </h5>
                        </div>
                    </div>
                    <div class="col-sm-5 box-features-mobile  ms-2 me-2 ">
                        <img className='apps-image' src="https://storage.googleapis.com/imagina-static/images/site/features/event/inform/app/app/fr/web/visuel_event_1.webp" />
                    </div>
                </div>
            </div>
        </div>

        <div className='formules-box ' id="package">
            <div class="container ">
                <div class="row div-formules pb-4 bg-white justify-content-center">
                    <h3 className='formule mt-4 mb-4'>NOS <b className='text-success'>FORMULES </b> </h3>
                    <div class="col-sm-4 box-features pt-4 ps-4 mt-1">
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Developers</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '> Conçue pour tests des developpeurs </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>   
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Jusqu'à 15 campagnes le mois. </h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 500k Push notifications autorisés. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 1 Go de stockage disponibles. </h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 		
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>				
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Vos tests sur l'app Fleetbo devbo.</h5>       
                        </div>
                        <div className='box-details-prices'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> <i class="fa-solid fa-gift me-1"></i> Gratuit </h3>
                            <h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> <b className='ms-0 text-dark' style={{fontWeight: '530', fontSize: '13px'}}> 30 jours offerts</b> </h4>
                            <h4 className='fs-6 text-success'> <i class="fa-solid fa-toggle-on me-1"></i> <b  style={{ fontWeight: '505'}}>Launch</b> après 30 jours</h4> 
                        </div>
                        <br /> 
                    </div>
                    <div class="col-sm-4 box-features bf-launch pt-4 ps-4 ms-4 me-4" style={{ border: '3px solid #05b365;' }}>
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Launch</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '> Conçue pour les startups en démarrage </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            <h5 className='details '><i class="fa-solid fa-check text-success fw-bold me-2"></i> Jusqu'à 70 campagnes par an. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check text-success fw-bold me-2"></i> 3 M Push notifications par mois. </h5>
                            <h5 className='details '><i class="fa-solid fa-check text-success fw-bold me-2"></i> 50 Go de stockage disponibles. </h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5>   
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> API Rest intégrée pour vos requêtes. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>  						
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Vos fichiers apk et IPA en 24H.</h5>   
                        </div>
                        <div className='box-details-prices'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> 149 &euro;  </h3>
                            <h4 className='mt-2 fs-6 text-success' style={{fontWeight: '600'}}> 17 &euro; / mois <b className='fs-6 me-1 ms-1 text-dark' style={{fontWeight: '400'}}>OU</b> <span className='text-dark tarif'><sup> 190 &euro; / an</sup></span></h4>
                            <h4 className='fs-6 text-success'> <i class="fa-solid fa-gift me-1"></i> 2 mois offerts </h4> 
                            {/* <Link  className='btn-start bg text-center mt-2'>Démarrer <i class="fas fa-tachometer-alt me-2"></i></Link> */}
                        </div>
                        <br />
                    </div>
                    {/* 
                    <div class="col-sm-3 box-features ms-2 me-2 mt-1">
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Scale</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '> Conçue pour les startups en croissance </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Idéale pour 100 k à 500 k users.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Push notification simplifié. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 01 base de données fournie. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 300 Go de stockage disponibles. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès au micro du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Fichier apk (Android) et IPA (IOS).</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 24 mises à jour autorisées.</h5>
                            <h5 className='details'><i class="fa-solid fa-check me-2"></i>  Jusqu'à 70 campagnes par an. </h5>
                        </div>
                        <br />
                        <div className='box-details-prices'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> 59 &euro; / mois <b className='fs-6 text-dark fw-normal'>OU</b> <span className='text-dark tarif'><sup> 620 € / an</sup></span></h3>
                            <h4 className='fs-6 fw-bold text-success'> <i class="fa-solid fa-gift me-1"></i> 30 jours offerts </h4> 
                        </div>  
                        <br /> 
                    </div>
                    
                    <div class="col-sm-3 box-features ms-2 me-2 mt-1">
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Entreprises</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '> Conçue pour les entreprises qui ont des révenus </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Pour plus de 500 k users.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Push notification simplifié. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> X base de données fournie. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> X Go de stockage disponibles. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès au micro du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Fichier apk (Android) et IPA (IOS).</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> 24 mises à jour autorisées.</h5>
                            <h5 className='details'><i class="fa-solid fa-check me-2"></i>  Jusqu'à 70 campagnes par an. </h5>
                        </div>
                        <br />
                        <div className='box-details-prices'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> Nous contacter <sup><img src={assist} className="assistant" alt="logo" /></sup> </h3>
                            <h4 className='fs-6 fw-bold text-success'> <i class="fa-solid fa-gift me-1"></i> 60 jours offerts </h4> 
                            <a href='/Basic' className='btn-start fs-6 text-center mt-2'>Contacter <i class="fa-solid fa-envelope me-2"></i> </a>
                        </div>
                        <br /> 
                    </div>
                    */}
                    { /***
                    <div class="col-sm-3 box-features ms-2 me-2 mt-1">
                        <h5 className='text-dark fw-bold mt-3'> <b className='text-success fw-bolder'>Entreprise</b> </h5>
                        <div className='box-details-desc pb-3'>
                            <span className='details-desc '>Conçue pour la migration vers FleetBo </span>
                        </div>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Idéal pour plus de 500 k utilisateurs.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Système d'authentification intégré.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Push notification optimisé et simplifié. </h5> 
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès à la camera du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Accès aux fichiers du téléphone.</h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Fichier apk (Android) et IPA (IOS).</h5>
                            <h5 className='details'><i class="fa-solid fa-check me-2"></i>  Jusqu'à 70 campagnes par an. </h5>
                            <h5 className='details '><i class="fa-solid fa-check me-2"></i> Un outil de statistique.</h5>
                        </div>
                        <div className='box-details-desc'>
                            <h3 className='mt-2 fs-4 fw-bold text-success'> Nous contacter <sup><img src={assis} className="assistant" alt="logo" /></sup> </h3>
                            <h4 className='fs-6 fw-bold text-success'> <i class="fa-solid fa-gift me-1"></i> 60 jours offerts </h4> 
                        </div>
                        <div className='box-details-button mt-5 pb-3'>
                            <a href='/Basic' className='btn-start mt-1'>Contacter <i class="fa-solid fa-envelope me-2"></i> </a>
                        </div>
                    </div>
                    **/ }
                    
                </div>
            </div>
        </div>

        <div className='formules-box'>
            <div class="container box-devFleet pb-4 bg-white">
                <div class="row box-devFleet-mobile justify-content-center">
                    <h3 className='formule mt-4 mb-4'>Pourquoi <b className='text-success'> Fleetbo </b> </h3>
                    <div class="col-sm-4 box-features ms-2 me-2 mt-2">
                        <h5 className='detail-fleetbo text-dark fw-bolder mt-4'>
                            <b>Technologie Fleetbo</b> <br />
                            <b className='detail-fleetbo-desc fw-bold mt-1 text-success'>Pourquoi Fleetbo dans votre équipe ?</b>
                        </h5>
                        <div className='col-md-12 div-details-welcome mb-5 pt-3 pb-3'>
                            {/**  
                            <h5 className='detail-fleetbo-desc'>
                                <b className='fw-bolder'>Pourquoi développer avec FleetBo ?</b> <br /> 
                                Notre équipe evalue toutes les applications Fleetbo: <br /> <br /> 
                                &bull; Maitrise du framework <br />
                                &bull; Vitesse de chargement <br />
                                &bull; Fluidité des interfaces <br /> 
                                &bull; Stabilité de l'application
                            </h5>
                            */}
                            <h5 className='detail-fleetbo-desc'>
                                Fleetbo permet aux développeurs JavaScript de développer des apps natives.  <br /> <br /> 

                                <i class="fa-solid fa-arrow-right me-2"></i> Pas de courbe d'apprentissage. <br />
                                <i class="fa-solid fa-arrow-right me-2"></i> Développement orienté web. <br />
                                <i class="fa-solid fa-arrow-right me-2"></i> Focus sur vos fonctionnalités. <br />
                                <i class="fa-solid fa-arrow-right me-2"></i> Faible coût de développement. <br />
                                <i class="fa-solid fa-arrow-right me-2"></i> Faible coût de maintenance. <br /> 
                                <i class="fa-solid fa-arrow-right me-2"></i> Maintenance auto régulière. <br /> 
                                <i class="fa-solid fa-arrow-right me-2"></i> Equipe homogène et réduite.
                            </h5>
                        </div>
                    </div>
                    <div class="col-sm-7 box-features-mobile p-0 ms-2 me-2">
                        <img src={developers} className='developers-image' alt="logo" /> 
                    </div>
                </div>
            </div>
        </div>  

       {/* 
        <div className='formules-box ' id="package">
            <div class="container pb-4 bg-white">
                <div class="row justify-content-center">
                    <h3 className='formule mt-4 mb-4'>Développeurs certifiés <b className='text-success'>Fleetbo </b> </h3>

                    <div class="col-sm-3 box-features p-0 ms-2 me-2 mt-4">
                        <div className='' style={{ width: '100%' }}>
                            <img style={{ width: '100%' }} src='https://www.studioah.fr/wp-content/uploads/2021/07/photo_professionel_studio_nantes_%C2%A9studioah_portrait_corporate-11-768x768.jpg' /> 
                        </div>
                        <div className='col-md-12 p-2 div-details-welcome'>
                            <h5 className='text-success'> Loic Standerson <sup className='text-light p-1' style={{ borderRadius: '12px', backgroundColor: '#d19507', fontWeight: '550', fontSize: '11px' }}> +10 d'exp </sup></h5>
                            <h5 className='fs-6' style={{ color: '#545454' }}><i class="fa-solid fa-location me-1"></i> Paris, France</h5>     
                            <div className="">
                                <div class="row" style={{ fontSize: '12px', fontWeight: '550' }}>
                                    <div class="col-md-auto p-2">
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>React JS</span>
                                    </div>
                                    <div class="col-md-auto p-2" >
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>Node JS</span>
                                    </div>
                                </div>
                                <br />
                                <a href='/Basic' className='btn-start fs-6 text-center mt-1'>Contacter <i class="fa-solid fa-link me-2"></i> </a>
                            </div>
                            <br />
                        </div>
                        <br /> <br />
                    </div>

                    <div class="col-sm-3 box-features p-0 ms-2 me-2 mt-4">
                        <div className='' style={{ width: '100%' }}>
                            <img style={{ width: '100%' }} src='https://www.studioah.fr/wp-content/uploads/2021/07/photo_professionel_studio_nantes_%C2%A9studioah_portrait_corporate-11-768x768.jpg' /> 
                        </div>
                        <div className='col-md-12 p-2 div-details-welcome'>
                            <h5 className='text-success'> Loic Standerson <sup className='text-light p-1' style={{ borderRadius: '12px', backgroundColor: '#d19507', fontWeight: '550', fontSize: '12px' }}> +5 </sup></h5>
                            <h5 className='fs-6' style={{ color: '#545454' }}><i class="fa-solid fa-location me-1"></i> Paris, France</h5>     
                            <div className="">
                                <div class="row" style={{ fontSize: '12px', fontWeight: '550' }}>
                                    <div class="col-md-auto p-2">
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>React JS</span>
                                    </div>
                                    <div class="col-md-auto p-2" >
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>Node JS</span>
                                    </div>
                                </div>
                                <br />
                                <a href='/Basic' className='btn-start fs-6 text-center mt-1'>Contacter <i class="fa-solid fa-link me-2"></i> </a>
                            </div>
                            <br />
                        </div>
                        <br /> <br />
                    </div>

                    <div class="col-sm-3 box-features p-0 ms-2 me-2 mt-4">
                        <div className='' style={{ width: '100%' }}>
                            <img style={{ width: '100%' }} src='https://www.studioah.fr/wp-content/uploads/2021/07/photo_professionel_studio_nantes_%C2%A9studioah_portrait_corporate-11-768x768.jpg' /> 
                        </div>
                        <div className='col-md-12 p-2 div-details-welcome'>
                            <h5 className='text-success'> Loic Standerson <sup className='text-light p-1' style={{ borderRadius: '12px', backgroundColor: '#d19507', fontWeight: '550', fontSize: '12px' }}> +20 </sup></h5>
                            <h5 className='fs-6 text-dark'><i class="fa-solid fa-location me-1"></i> Paris, France</h5>     
                            <div className="">
                                <div class="row" style={{ fontSize: '12px', fontWeight: '550' }}>
                                    <div class="col-md-auto p-2">
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>React JS</span>
                                    </div>
                                    <div class="col-md-auto p-2" >
                                        <span className='ps-2 pe-2 pt-1 pb-1 mt-3' style={{ borderRadius: '11px', fontWeight: '550', backgroundColor: '#75f393' }}> <i class="fa-solid fa-check me-1"></i>Node JS</span>
                                    </div>
                                </div>
                                <br />
                                <a href='/Basic' className='btn-start fs-6 text-center mt-1'>Contacter <i class="fa-solid fa-link me-2"></i> </a>
                            </div>
                            <br />
                        </div>
                        <br /> <br />
                    </div>
                
                </div>
                <br /> <br />
                <a href='/Basic' className='fw-bold text-dark fs-5 text-center'> <h4 className='fs-6 fw-bolder '>Plus de développeurs  <i class="fa-solid fa-plus me-2"></i> </h4></a>
            </div>    
        </div>
        
        */}
        
        <div className='ps-3 pe-3 pt-3 pb-2'  id='package'> </div>

        <div className='pt-3'>
            <div class="container">
                <div class="row justify-content-center mb-2">
                    <h3 className='formule'>Ils nous font <b className='text-success'>confiance</b> </h3>
                    <div class="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-4">
                    <img className='logos' src="https://i0.wp.com/www.univ-dschang.org/wp-content/uploads/2023/08/logo-UDs-Article.jpg?fit=665%2C626&ssl=1" alt="Logo" /> <br />
                    <span className='partners'>UDs</span>
                    </div>
                    <div class="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-4">
                    <img className='logos' src="https://www.talanoo.com/img/tal1.png" alt="Logo" /> <br />
                    <span className='partners'>Talanoo</span>
                    </div>
                    <div class="col-sm-1  pt-4 pb-4 ms-3 me-3 mt-4 mb-4">
                    <img className='logos' src="https://upload.wikimedia.org/wikipedia/fr/a/ad/FT_logo-RS_%281%29.png" alt="Logo" /> <br />
                    <span className='partners'>La French Tech</span>
                    </div>
                </div>
            </div>
        </div>
    
        <div className='ps-3 pe-3 pt-3 pb-2' > </div>

        <Footer />

        </div>
    );
}

export default Welcome;

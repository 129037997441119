import React from 'react';
import './css/Navbar.css';
import assistant from './img/assistant.png';

const NavbarHome = () => {
  return (

<nav className="navbar fixed-top">
  <div className="navbar-left">
    <a href="javascript:history.back()">
       <i class="fas fa-arrow-left ms-1 fw-bold text-success"></i>
    </a>
  </div>
  <div className="navbar-right">
    <a className="cart-icon">
      <i class="fas fa-globe fs-4"></i>
    </a>
    <a href="/login" className="cart-icon">
      <i class="fas fa-circle-question fs-4"></i>
    </a>
  </div>
</nav>
);
};

export default NavbarHome;
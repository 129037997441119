import React, { useEffect, useState } from "react";
import { db, auth } from "./../firebase";
import { collection, getDoc, doc, onSnapshot } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import './../css/Home.css';
import './../css/ProgressBar.css';

import Navbar from './../navbars/NavbarProject';
import NavbarGenerate from './../navbars/NavbarGenerate';
import Dashboard from './Dashboard';
import Generate from './Generate';


function Project() {

  //const { currentUser } = useAuth();
  const [data, setData] = useState([]);

  const [user, loading, error ] = useAuthState(auth);
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState(JSON.parse(localStorage.getItem('notifications')) || null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    const fetchData = async () => {
        const id = 'WHsQQDGqPAWizwXL3EAg'; // Define id here
        const docRef = doc(db, "entreprises", id);
        const docSnap = await getDoc(docRef);
      
        if (docSnap.exists()) {
          const docData = docSnap.data();
          const data = {
            nom: docData.nom, // Replace 'field1' with your actual field name
          };
          setData(data);
          localStorage.setItem('Company', docData.nom);
        } else {
          console.log("No such document!");
        }
      };
      
    fetchData();


    const id = 'xgPbibFSPa585I0rQOML'; // Define id here
    const collectionRef = collection(db, `Goupnotifs/${id}/notifications`);

    const unsubscribe = onSnapshot(collectionRef, (collectionSnap) => {
      if (!collectionSnap.empty) {
        const newNotifications = collectionSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        
        const cachedData = JSON.parse(localStorage.getItem('notifications'));
        if (JSON.stringify(newNotifications) !== JSON.stringify(cachedData)) {
          setNotifications(newNotifications);
          localStorage.setItem('notifications', JSON.stringify(newNotifications));
        }

        setIsLoading(false);
      } else {
        alert('No such notifications!');
        console.log('No such notifications!');
        setIsLoading(false);
      }
    });

    return () => unsubscribe();

  }, []);

    if (isLoading) {
        return <div className="App-Home"><><div className="fs-3 fw-normal text-dark p-5">
        <Spinner animation="grow" variant="warning" role="status">
            <span className="visually-hidden text-light">Loading...</span>
        </Spinner>
    </div></> </div>;
    }

    if (error) {
        return <div className='fs-6'>Error: {error}</div>;
    }



  return (
    <div className="App"  style={{ background: '#02a049' }}>
        <Navbar />
        <header className="App-Home">
          <div className='col-md-7 pt-1 '>
              {data ? <h2 className='fw-bolder text-white mt-5'>{data.nom}</h2> : <p>Loading...</p>} 
              <div className="container col-md-12 p-0 ">
                 
                    <div className="col-md-12 box-campagne bg-white ps-2  pe-2 pb-5 ">
                        <h5 className='text-dark fs-5 fw-bold mt-3 mb-3'>  
                            Campagnes
                            <a href='/notification' className='ms-2'>
                                <b className='text-success ms-2'><i className="far fa-paper-plane"></i></b> 
                            </a>
                        </h5>  
                        <div className='col-md-12 ps-2 pe-2 pt-4 pb-2' style={{background: 'rgb(243, 248, 247)', height: '95%'}} >
                            <div>
                                {/*<p>{user.email}</p> */}
                                {notifications.map((item) => (
                                    <div key={item.id}> 
                                        <div className='mb-4'>
                                            <h5 className='description'> {item.content} </h5>  
                                            <h5 className='target'> <b className=''>2579 / 3000</b> users touchés   </h5>
                                            <h5 className='datepush'> {item.dateCreated}</h5>  
                                        </div>
                                        <hr></hr>
                                    </div>
                                ))}
                            </div>
                        </div>                              
                    </div>

              </div>
              <p className='text-desc text-center mt-1' style={{ fontSize: '14px'}}>First App-as-a-Service for web developers.</p>
              <p className='text-success text-center text-light' style={{  fontSize: '12px'}}><b>Fleetbo</b> &copy; 2024</p> 
          </div>
        </header>
    </div>
  );
}

export default Project;

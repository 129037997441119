import React, { useEffect, useState }  from 'react';
import './css/Navbar.css';
import './css/Home.css';
import logo1 from './img/logo1.png';
import assistant from './img/assistant.png';
import {  auth } from "../firebase";
import {  useNavigate } from 'react-router-dom';
import Logout from './../Logout';

const NavbarProject = () => {

    const [company, setCompany] = useState();

    useEffect(() => { 
        setCompany(localStorage.getItem('Company'));     
    })

  return (

    <nav className="navbar fixed-top">
        <div className='container'>
            <div className="navbar-left">
                <a href="javascript:history.back()">
                   <i className="fas fa-arrow-left ms-1 fw-bold text-success"></i>
                   <span className='fs-6 ms-3 text-success'>{company}</span> 
                </a>
            </div>
            <div className="navbar-right">
                {/*
                <a href="/messages" className="cart-icon">
                    <i className="fas fa-envelope me-3 mt-2"></i>
                    <span className="cart-count me-3 mt-2 fw-bold">3</span>
                    update
                </a>
                */}
                <a href="/mobile" className="cart-icon">
                    <i class="fa-solid fa-mobile me-2 mt-2"></i>
                    <span className='fs-6 fw-normal'>Update</span>
                </a>
                <a href="/documentation" className="cart-icon">
                    <img src={assistant} className="assistant" alt="logo" /> 
                </a>
            </div>
        </div>
    </nav>
);
};

export default NavbarProject;
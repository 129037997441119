import React, { useEffect, useState } from "react";
import './css/Welcome.css';
import NavbarHome from './navbars/NavbarHome';
import NavbarLogin from './navbars/NavbarLogin';
import { db, auth } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import { useAuthState }  from 'react-firebase-hooks/auth';
import { useNavigate, Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';



function Home() {


  //const { currentUser } = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [use ] = useAuthState(auth);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'entreprises'));
        const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Sort items in ascending order based on dateCreated
        const sortedItems = items.sort((a, b) => a.dateCreated - b.dateCreated);
        
        setData(sortedItems);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
      return <div><><div className="fs-3 fw-normal text-dark p-5">

  </div></> </div>;
  }

  return (
    <>
    {loading? (
      <div>  </div>
    ): (
      <div>
        {use? (
          <NavbarHome />
        ):(
          <NavbarLogin />
        )}
      </div>
    )}
    <div style={{ background: '#02a049' }}>

      <div className='ps-5 pe-5 pb-5 App-header'>
          <div class="container">
              <div class="row ">
                  <div className='mt-1'><h1 className='fs-2 text-white'>Mes projets</h1> </div>
                  {data.map((item, index) => (
                    item.status === true ? (
                    <div key={index} class="col-sm-3 box-features pt-3 ps-3 pe-3 pb-2 ms-2 me-4 mt-4">
                        <h5 className='text-dark fw-bold mt-3 ms-2'> <b className='text-success fw-bolder'>{item.nom}</b> </h5>
                        <div className='box-details-desc ps-2 pe-2 pb-3'>
                            <span className='details-desc '> 35 0000 users </span> <br />
                            <i class="fa-brands fa-apple fs-6 text-dark mt-2 me-2"></i>
                            <i class="fa-brands fa-android fs-6 text-dark mt-2 "></i> 
                        </div>
                        <div className='box-details-button mt-2 ps-2 pe-2 pb-3'>
                            <Link to={`/project/${item.id}`} className='btn-start mt-3'>Ouvrir <i class="fas fa-tachometer-alt me-2"></i></Link>
                        </div>
                        <br /> 
                    </div>
                    ) : (
                    <div key={index} class="col-sm-3 box-features pt-3 ps-3 pe-3 pb-2 ms-2 me-4 mt-4">
                        <h5 className='text-dark fw-bold mt-3 ms-2'> <b className='text-success fw-bolder'>{item.nom}</b> </h5>
                        <div className='box-details-desc ps-2 pe-2 pb-3'>
                            <span className='details-desc fw-bold '> Loading... 70% </span>
                        </div>
                        <div className='box-details-button mt-2 ps-2 pe-2 pb-3'>
                            <Link disabled className='disabled mt-3'>
                                <Spinner animation="grow" variant="warning" role="status" size="sm">
                                        <span className="visually-hidden text-dark">Loading...</span>
                                </Spinner>
                                <Spinner animation="grow" variant="warning" role="status" size="sm" className='ms-1'>
                                        <span className="visually-hidden text-dark">Loading...</span>
                                </Spinner>
                                <Spinner animation="grow" variant="warning" role="status" size="sm" className='ms-1'>
                                        <span className="visually-hidden text-dark">Loading...</span>
                                </Spinner>
                            </Link>
                        </div>
                        <br /> 
                    </div>
                    )
                  ))}
                 
              </div> 
            </div>
        </div> 
    </div>
    </>
  );
}

export default Home;

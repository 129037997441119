import React, { useEffect, useState } from "react";
import './../css/Welcome.css';
import { Player } from 'video-react';
import Navbar from './../navbars/NavbarForm';
import { auth, db } from './../../firebase';
import { useAuth } from '../firebase/AuthContext';
import {
    collection,
    addDoc,
    getDocs,
    updateDoc,
    deleteDoc,
    doc
  } from 'firebase/firestore';
import { useForm } from 'react-hook-form';
import { useAuthState }  from 'react-firebase-hooks/auth';
import { Navigate, useNavigate } from 'react-router-dom';

function Notification() {


   const [message, setMessage] = useState('');

   //const [user, setUsers] = useState(null);
   //const [error, setError] = useState('');

  
   const [user, loading, error ] = useAuthState(auth);
   const navigate = useNavigate();
   if(!user){
    navigate('/login');
   }

   const [content, setContent] = useState('');
   //const entrepriseId = user.uid;
   const date         = new Date();

   const dateCreated  = date.toString();


   const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
  
      try {
        const entrepriseId = user.uid
        await addDoc(collection(db, 'Goupnotifs'), { content: content, entrepriseId, dateCreated });
        setMessage('Message publié !');
        setContent(''); // Clear the input field after successful submission
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
  };


  useEffect(() => {
     //alert('User: ' + user.uid)
  });


  return (
    <div className="App">
      <Navbar />
      <header className="App-header">
        <div className='col-md-11 ms-2 me-2 row justify-content-center'>
            <div class="col-sm-6 p-2">
              <br />
                <h5 className='mt-5 ms-2'> FleetBo </h5>
                <div className='col-md-12 ps-2 pe-2 pt-2 pb-2 '> 
                    <p className=''>
                      <span className='title1'>
                          <span className='brand me-2'>
                            Navigateurs <i class="fa-brands fa-edge-legacy ms-1 brand"></i> <i class="fa-brands fa-firefox-browser ms-1 brand"></i>
                          </span> 
                          | 
                          <span className='brand ms-2'>Téléchargements</span> 
                          <i class="fa-brands fa-google-play brand ms-1"></i> <i class="fa-brands fa-apple brand"></i>
                      </span>
                      <br />
                      <div className=''>
                      <form onSubmit={handleSubmit}>
                        <div className='justify-content-center'>
                                <div className=' mb-3'>
                                    <label className='ps-0 fs-6 label'>Notifiez vos utilisateurs (90 caractères maximum)</label>
                                    <textarea type="text" 
                                              className='form-control'
                                              value={content}
                                              onChange={(e) => setContent(e.target.value)}
                                              placeholder="Message..." maxLength='90'
                                              id="exampleFormControlTextarea1" rows="2"
                                              required
                                    >
                                    </textarea>    
                                </div>
                                {user ? (
                                <div className='mb-2 justify-content-center d-flex'>
                                    <button type='submit' className='connexion' >  
                                        Publier <i className="fas fa-paper-plane me-3 fw-bold"></i> 
                                    </button>
                                    <br />
                                </div>
                                ) : (
                                <div className='mb-2 justify-content-center d-flex'>
                                  <h5  className='' >  Veuillez vous connecter  </h5>
                                </div>
                                )}  
                                {error && <p>{error}</p>}
                                {message && 
                                <p className='fs-6'>{message}</p>
                                }
                        </div>
                      </form>
                      </div>
                      <b className='title2 fs-6'>Ce message sera adressé à tous vos utilisateurs </b>
                    </p>
                </div>
            </div>
            <div class="col-sm-5 bg-media ">
                 <video width="420" height="305" src="https://assets.codepen.io/6093409/hubspot-video-example.mp4" controls autoplay></video>
            </div>
        </div>
        {/*
        <div className='ps-3 pe-3'>
           <p className='text-desc'>Transformez vos sites web en Appli non téléchargeable.</p>
        </div>
        */}
      </header>

      <footer className="footer pt-2 pb-1">
        <div>
           <h6 className='text-success'><b>FullApp</b> &copy; 2024</h6> 
        </div>
      </footer>
      
    </div>
  );
}

export default Notification;

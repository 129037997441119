import React from 'react';
import './css/Navbar.css';
import assistant from './img/assistant.png';

const NavbarPublisher = () => {
    return (
        <nav className="navbar fixed-top">
            <div className='container'>
                <div className="navbar-left">
                    <a href="javascript:history.back()">
                    <i class="fas fa-arrow-left ms-1 fw-bold text-success"></i>
                    </a>
                </div>
                <div className="navbar-right">
                     <span className='go-to-console'>New project </span>
                </div>
            </div>
        </nav>
    );
};

export default NavbarPublisher;